import { App } from "./app"
import { Config } from "./config"
import { TileInfo, TileType, getTileInfo, getTileId, TileId, filterTilesNames } from "./tiles"
import { GameMap, MapChunk } from "./game-map"
import { Character } from "./character"
import { CharacterAI_RandomRoam } from "./ai"
import { getRandomInt, randomChoice } from "./core"
import { assert } from "./assert"
import { FortressUtils } from "./fortress-utils"
import { ThingFactory } from "./thing-factory"
import { TextureUtils } from "./textures"
import { gameState } from "./game-state"
import { ChunkGeneratorTest, ChunkGeneratorSimplex } from "./chunk-generator"
import { TemplatePainter } from "./structure-templates"
import { WorldGenerator } from "./world-generator"
import { TileLevel } from "./enums"

const WORLD_SIZE = 128

// -----------------------------------------------------------------------------
// Initialize standard world - things and characters
// -----------------------------------------------------------------------------
export function initializeStandardWorld(app: App) {
    assert(TileId.EMPTY !== -1, "TileId.EMPTY should not be -1 (1)")

    // !!! GENERATE THE WORLD !!!
    const worldInfo = WorldGenerator.generate(Config.worldMapSeed)
    gameState().theChunkGenerator = new ChunkGeneratorSimplex()
    gameState().theChunkGenerator!.worldInfo = worldInfo

    // console.log('=== === ===  === === ===')
    // console.log('=== World Generation ===')
    // console.log('=== === ===  === === ===')
    // console.log(JSON.stringify(worldInfo, null, 2))

    // TODO: Actual World Generation System
    // ...
    // - Decide where the structures are supposed to be using world seed
    //   + Simple: Inner World: generate apriori the coordinates of the structures and store them
    //   + Simple: Outer World: generate the coordinates of the structures on the fly prcedurally
    //     - Compute probability of generating WitchHut for example, if yes compute a random priority
    //     - Compute probability of generating WitchHut in N chunks radius and their priority
    //     - Only render WitchHut in current block if is the one with highest priority | plus x/y for tie breaker
    // - Load/unload logic: if one chunk touches a structure, load the structure and all chunks touched by it
    //   ... or load structure only once all chunks touced by it are loaded, ie, the structure fits completely in the
    //       current virtual space
    // - Handle load/unload of characters that might have exited the structure
    //   ... unload all characters that are in the chunks deleted but keep chars that are in loaded chunks but
    //       assigning known ids make sure to not recreate them again and recycle the existing ones when the structure
    //       is loaded again
    //   ... character AI must handle situation where their structure has been unloaded
    const map = new GameMap

    // const emptyCell = getTileId('Floor-8-005')
    // const fillCell = getTileId('Wall-8-005')
    // const dimensions = { w: WORLD_SIZE, h: WORLD_SIZE } // Starcraft 2 map size, while Warcraft 3 is 128x128, and Age of Empires 2 is 256x256
    // const map: GameMap = FortressUtils.cellularAutomataCaves({
    //     maxPasses: 5,
    //     threshold: 4,
    //     caveProbability: 53,
    //     circleRadius: Math.min(dimensions.w, dimensions.h) / 2.2,
    //     border: 5,
    //     emptyCell,
    //     fillCell,
    //     dimensions,
    // })

    // Init Fog of War for the map
    map.initializeFowMap()
    map.initializeThingMap()
    gameState().addFloor(map)

    // testChunkGenerator(map)

    // initializeFloorRooms(map, emptyCell)
    // initializeFloorAndWallGallery(map)
    // Populate the map with things and characters
    // initializeStandardWorld_ThingsAndCharacters(gameState().theFloor!)

    // Initialize player and position
    initializeStandardWorld_Player(map)

    // Standard initialization
    // Center camera to player
    const renderer = gameState().theMapCameraRenderer!
    renderer.centerCameraToCharacter(gameState().thePlayer)
    renderer.updateChunks()

    // TODO: move this into the world generation logic
    // const px = gameState().thePlayer.x
    // const py = gameState().thePlayer.y
    // TemplatePainter.drawTemplate_Structure('Castle', {}, px - 10, py - 10, map)
    // TemplatePainter.drawTemplate_Things('Castle', {}, px - 10, py - 10, map)
    // TemplatePainter.drawTemplate_Characters('Castle', {}, px - 10, py - 10, map)
}

// -----------------------------------------------------------------------------
// Initialize standard world - things and characters
// -----------------------------------------------------------------------------
function initializeStandardWorld_Player(map: GameMap): void {
    const thePlayer: Character = gameState().thePlayer
    assert(thePlayer !== null, 'thePlayer is null')
    thePlayer.x = 16 * 1000
    thePlayer.y = 16 * 1000
    thePlayer.name = 'Sir Lancelot'
    thePlayer.type = 'Player'
    const playerTexture = 'Chara6-0'
    // const playerTexture = 'Monster-Lich-0'
    thePlayer.setAnimationTextures(playerTexture)
    // add the player to list of characters
    map.characters.push(thePlayer)
    // add to physics
    gameState().addToPhysics(thePlayer)
}

function initializeFloorAndWallGallery(map: GameMap) {
    const nx = 5
    const ny = 5
    const rw = 6 // room width
    const rh = 6 // room width
    const border = 2
    const startX = rw
    const startY = rh

    const wallTileNames = filterTilesNames((tileName: TileInfo) => tileName.level === TileLevel.WALL)
    const floorTileNames = filterTilesNames((tileName: TileInfo) => tileName.level === TileLevel.FLOOR)

    // draw wrapping empty room around the w * h rooms
    const w = rw * nx + border * (nx - 1)
    const h = rh * ny + border * (ny - 1)
    FortressUtils.drawRoom(map, startX - 1, startY - 1, w + 2, h + 2, TileId.FLOOR_GRASS, TileId.FLOOR_WATER_SHALLOW)

    // draw rooms
    let floorIndex = 0
    const floorPage = 0
    let wallIndex = 0
    const wallPage = 0
    for (let i = 0; i < ny; ++i) {
        for (let j = 0; j < nx; ++j, ++floorIndex, ++wallIndex) {
            const wallCellName = randomChoice(wallTileNames)
            // const wallCellName = wallTileNames[(wallIndex + wallPage * nx * ny) % wallTileNames.length]

            const floorCellName = randomChoice(floorTileNames)
            // const floorCellName = floorTileNames[(floorIndex + floorPage * nx * ny) % floorTileNames.length]

            const wallCellId = getTileId(wallCellName)
            const floorCellId = getTileId(floorCellName)
            const rx = j*rw + j*border + startX
            const ry = i*rh + i*border + startY
            FortressUtils.drawRoom(map, rx, ry, rw, rh, floorCellId, wallCellId)

            // make opening & door on random location: top, left, right, bottom
            let doorX = 0
            let doorY = 0
            switch(getRandomInt(0, 3)) {
                case 0: doorX = rx + Math.floor(rw / 2); doorY = ry; break;
                case 1: doorX = rx; doorY = ry + Math.floor(rh / 2); break;
                case 2: doorX = rx + rw - 1; doorY = ry + Math.floor(rh / 2); break;
                case 3: doorX = rx + Math.floor(rw / 2); doorY = ry + rh - 1; break;
            }
            map.setSafe_(doorX, doorY, TileId.FLOOR_DIRT);
            // const door = ThingFactory.getThingInstance(randomChoice(['DoorClosed', 'DoorOpen']))
            const door = ThingFactory.getThingInstance('Door')
            map.placeThing(door, doorX, doorY, false)
        }
    }
}

function initializeFloorRooms(map: GameMap, emptyCell: TileType) {
    // map center
    const roomSize = 29
    const halfRoomSize = Math.floor(roomSize / 2)
    const centerX = Math.floor(WORLD_SIZE / 2)
    const centerY = Math.floor(WORLD_SIZE / 2) - 10
    FortressUtils.drawRoom(map, centerX - halfRoomSize -1, centerY - halfRoomSize -1, roomSize, roomSize, emptyCell, emptyCell)
    const galleryX = Math.floor(centerX - roomSize / 2);
    const galleryY = Math.floor(centerY - roomSize / 2);

    [
        TileId.FLOOR_CAVE,
        TileId.FLOOR_GRASS,
        TileId.FLOOR_DIRT,
        TileId.FLOOR_ROAD,
        TileId.FLOOR_SAND,
        TileId.FLOOR_SNOW,
        TileId.FLOOR_ICE,
        TileId.FLOOR_SWAMP,
        TileId.FLOOR_STONE,
        TileId.FLOOR_WOOD,
        TileId.FLOOR_WATER_SHALLOW,
        TileId.FLOOR_WATER_RIVER,
        TileId.FLOOR_WATER_SEA,
        TileId.FLOOR_LAVA,

        TileId.WALL_CAVE,
        TileId.WALL_ROCK,
        TileId.WALL_STONE_EXT,
        TileId.WALL_BRICK_EXT,
        TileId.WALL_STONE_INT,
        TileId.WALL_BRICK_INT,

    ].forEach((tileId, i) => {
        const row = Math.floor(i / 5)
        const sampleSize = 3
        const x = galleryX + (i % 5) * sampleSize * 2 + 1
        const y = galleryY + row * sampleSize * 2 + 1
        FortressUtils.drawRoom(map, x, y, sampleSize, sampleSize, tileId, tileId)
    })
}

// // -----------------------------------------------------------------------------
// // Initialize standard world - things and characters
// // -----------------------------------------------------------------------------
// function initializeStandardWorld_ThingsAndCharacters(map: GameMap): void {
//     // assert(floor.map !== null, 'floor.map is null')
//     // const map: GameMap = floor.map!
//     const characters = map.characters

//     // make bridge - place middle of the map
//     map.placeThingSafe(ThingFactory.getThingInstance('Bridge'), Math.floor(WORLD_SIZE / 2) -1, Math.floor(WORLD_SIZE / 2 - 10))
//     map.placeThingSafe(ThingFactory.getThingInstance('Bridge'), Math.floor(WORLD_SIZE / 2) -1, Math.floor(WORLD_SIZE / 2 - 11))
//     map.placeThingSafe(ThingFactory.getThingInstance('Bridge'), Math.floor(WORLD_SIZE / 2) -1, Math.floor(WORLD_SIZE / 2 - 12))

//     // iterate over map tiles
//     for (let y = 0; y < WORLD_SIZE; ++y) {
//         for (let x = 0; x < WORLD_SIZE; ++x) {
//             const tile = map.getSafe(x, y)
//             const tileInfo = getTileInfo(tile)
//             if (tileInfo.config.canWalkThrough) {
//                 if (Math.random() < 0.5) {
//                     // FIXME: 0 --> 1
//                     if (Math.random() * 100 < 5) {
//                         const character = new Character
//                         character.setAI(new CharacterAI_RandomRoam(character, map))

//                         character.x = x + 0.5
//                         character.y = y + 0.5

//                         // character.type = randomChoice([
//                         //     'Player',
//                         //     'Knight1',
//                         //     'Ghost',
//                         //     'Zombie',
//                         //     'GruesomeEye',
//                         //     'DarkKnight1',
//                         //     'DarkKnight2',
//                         //     'DarkKnight3',
//                         //     'DarkKnight4',
//                         //     'DarkKnight5',
//                         //     'Goblin',
//                         //     'Skeleton',
//                         //     'Orc',
//                         //     'Vampire',
//                         //     'Spider',
//                         //     'Bat',
//                         //     'Snake',
//                         // ])

//                         const sheet = randomChoice([
//                             // { name: 'Chara6', range: 7 },
//                             // { name: 'Chara7', range: 7 },
//                             // { name: 'Chara8', range: 7 },
//                             // { name: 'Npc5', range: 7 },
//                             // { name: 'Npc6', range: 7 },

//                             { name: 'Orc1', range: 7 },
//                             // { name: 'Orc2', range: 7 },
//                             { name: 'Monster1', range: 7 },
//                             { name: 'Monster2', range: 7 },
//                             { name: 'Monster3', range: 7 },
//                             { name: 'Monster4', range: 7 },

//                             // { name: 'Horse', range: 7 },
//                             // { name: 'Mount1', range: 7 },
//                             // { name: 'Mount2', range: 7 },

//                             // { name: 'Elemental', range: 3 },
//                             // { name: 'Wizard', range: 3 },

//                             // { name: 'Monster-DNight1', range: 0 },
//                             // { name: 'Monster-DNight2', range: 0 },
//                             // { name: 'Monster-Bird1', range: 0 },
//                             // { name: 'Monster-Bird2', range: 0 },
//                             // { name: 'Monster-Bird3', range: 0 },
//                             // { name: 'Monster-Boar', range: 0 },
//                             // { name: 'Monster-Cacto', range: 0 },
//                             // { name: 'Monster-Elk', range: 0 },
//                             // { name: 'Monster-Golem1', range: 0 },
//                             // { name: 'Monster-Golem2', range: 0 },
//                             // { name: 'Monster-Lich', range: 0 },
//                             // { name: 'Monster-Lizardman1', range: 0 },
//                             // { name: 'Monster-Lizardman2', range: 0 },
//                             // { name: 'Monster-Minotaur', range: 0 },
//                             // { name: 'Monster-Phoenix', range: 0 },
//                             // { name: 'Monster-Raptor1', range: 0 },
//                             // { name: 'Monster-Raptor2', range: 0 },
//                             // { name: 'Monster-Treant', range: 0 },
//                             // { name: 'Monster-Wolf1', range: 0 },
//                             // { name: 'Monster-Wolf2', range: 0 },
//                         ])

//                         character.texture = Textures.getTexture('Player'/*character.type*/)
//                         character.setAnimationTextures(`${sheet.name}-${getRandomInt(0, sheet.range)}`)
//                         characters.push(character)
//                         // add to physics
//                         gameState().addToPhysics(character)
//                     }
//                 } else {
//                     if (Math.random() < 0.025) {
//                         // const thingNames = ThingFactory.getThingNames()
//                         const thingNames = [
//                             'Tree-Pink-1',
//                             'Tree-Pink-2',
//                             'Tree-Pink-3',
//                             'Tree-Pink-4',

//                             'Tree-Green1-1',
//                             'Tree-Green1-2',
//                             'Tree-Green1-3',
//                             'Tree-Green1-4',

//                             'Tree-Green2-1',
//                             'Tree-Green2-2',
//                             'Tree-Green2-3',
//                             'Tree-Green2-4',

//                             'Tree-Green3-1',
//                             'Tree-Green3-2',
//                             'Tree-Green3-3',
//                             'Tree-Green3-4',
//                         ]
//                         const thing = ThingFactory.getThingInstance(randomChoice(thingNames))
//                         map.placeThingSafe(thing, x, y, Math.random() > 0.5)
//                     }
//                 }
//             }
//         }
//     }
// }
