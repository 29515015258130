import { Config } from "./config"
import { FogOfWarType, CameraFollowMode, TileCategory, TileLevel } from "./enums"
import { getTileInfo } from "./tiles"
import { RGBA, Vec2D } from "./core"
import { assert } from "./assert"
import { shadowcast } from "./symmetric-shadowcasting"
import { Camera } from "./camera"
import { Character } from "./character"
import { MapCanvas, RenderContext } from "./map-canvas"
import { MapChunk, GameMap } from "./game-map"
import { FogOfWarClouds } from "./fog-of-war-clouds"
import { TextureUtils } from "./textures"
import { gameState } from "./game-state"
import { GameTime } from "./game-time"
import { Texture } from "./textures"
import { ChunkGeneratorTest, ChunkGeneratorSimplex } from "./chunk-generator"

// Used for penumbra
const neighborOffsets = [
    { dx: -1, dy: -1 },
    { dx: 0, dy: -1 },
    { dx: 1, dy: -1 },
    { dx: -1, dy: 0 },
    { dx: 1, dy: 0 },
    { dx: -1, dy: 1 },
    { dx: 0, dy: 1 },
    { dx: 1, dy: 1 },
]

///////////////////////////////////////////////////////////////////////////////
// RendererCanvas
///////////////////////////////////////////////////////////////////////////////
/**
 * @class RendererCanvas
 * @description Renders a map on a canvas using a camera
 */
export class RendererAbstract {
    protected mapCanvas: MapCanvas // canvas to render the map on

    protected camera: Camera

    viewportBorder: number = Config.TILE_PIXEL_SIZE * 4

    lastPlayerPosition: Vec2D = new Vec2D(0, 0)
    playerPath: Array<{ x: number, y: number }> = []

    drawVirtualCamera: boolean = !!0
    renderGridEnabled: boolean = !!0

    clearColor: RGBA = new RGBA(255, 0, 255, 255)
    gridColor: RGBA = new RGBA(128, 128, 128, 255)
    wallOutlineColor: RGBA = new RGBA(32, 32, 32, 255 * 0.75)

    scheduleUpdateFogOfWar: boolean = true

    fogOfWarType: FogOfWarType = FogOfWarType.NONE
    // fogOfWarType: FogOfWarType = FogOfWarType.BRIGHT
    // fogOfWarType: FogOfWarType = FogOfWarType.MEDIUM
    // fogOfWarType: FogOfWarType = FogOfWarType.DARK

    // // cameraFollowMode: CameraFollowMode = CameraFollowMode.NONE
    // cameraFollowMode: CameraFollowMode = CameraFollowMode.CENTER
    // // cameraFollowMode: CameraFollowMode = CameraFollowMode.BORDER

    // fogOfWarColor = new RGBA(192, 192, 192, 0) // day fog of war
    // fogOfWarColor = new RGBA(0, 0, 0, 0) // night fog of war
    // fogOfWarColor = new RGBA(128, 0, 0, 0) // demon fog of war
    // fogOfWarColor = new RGBA(64, 0, 128, 0) // vampire fog of war
    // fogOfWarColor = new RGBA(16, 32, 0, 0) // zombie fog of war
    fogOfWarColor = new RGBA(0, 16, 32, 0) // night beast - warewolf fog of war
    fogOfWarColor2 = new RGBA(-1, -1, -1, 0) // night fog of war

    // Simpler rendering
    isGallerySimpleRender = false

    protected fogOfWarClouds = new FogOfWarClouds()

    constructor(
        camera: Camera,
        mapCanvas: MapCanvas
    ) {
        this.camera = camera
        this.mapCanvas = mapCanvas

        const canvas = mapCanvas.getCanvasElement() // document.createElement('canvas')
        canvas.width = 100
        canvas.height = 100
        this.mapCanvas.getRC().initAndClearFrame(new RGBA(255, 0, 0, 255)) // first clear is red
        // initial red fill
        // const ctx = this.mapCanvas.getContext()
        // ctx.fillStyle = 'red'
        // ctx.fillRect(0, 0, canvas.width, canvas.height)

        this.fogOfWarClouds.initAutomata(128, 128)
    }

    getCamera(): Camera {
        return this.camera
    }

    getMapCanvas(): MapCanvas {
        return this.mapCanvas
    }

    getFloor(): GameMap {
        return gameState().theFloor!
    }

    centerCameraToCharacter(player: Character) {
        const self = this
        const map = self.getFloor()
        assert(map !== null, 'Map is null (1)')

        // const player = this.thePlayer
        // const canvas = this.mapCanvas.getCanvas()
        const px = player.x * Config.TILE_PIXEL_SIZE
        const py = player.y * Config.TILE_PIXEL_SIZE
        self.camera.viewLeft = Math.floor(px - self.camera.viewWidth / 2)
        self.camera.viewTop = Math.floor(py - self.camera.viewHeight / 2)
        // TODO: removed since we now have free maps
        // clamp camera position to map size
        // const maxWorldX = map.getW() * Config.TILE_PIXEL_SIZE - canvas.width
        // const maxWorldY = map.getH() * Config.TILE_PIXEL_SIZE - canvas.height
        // self.camera.viewLeft = Math.min(maxWorldX, Math.max(0, self.camera.viewLeft))
        // self.camera.viewTop = Math.min(maxWorldY, Math.max(0, self.camera.viewTop))
    }

    updateFogOfWar(): void {
        const self = this
        const map = self.getFloor()
        assert(map !== null, 'Map is null (2)')

        switch (this.fogOfWarType) {
            case FogOfWarType.NONE:
                map.fillFogOfWar(1)
                return
            case FogOfWarType.BRIGHT:
                // keep visible areas
                break
            case FogOfWarType.MEDIUM:
                const { vx, vy, vw, vh } = map.getVirtualBounds()
                for (let y = vy; y < vy + vh; ++y) {
                    for (let x = vx; x < vx + vw; ++x) {
                        map.setFow(x, y, Math.min(0.5, map.getFow(x, y)))
                    }
                }
                break
            case FogOfWarType.DARK:
                map.fillFogOfWar(0)
                break
        }

        // use computeFov to update fog of war map
        assert(gameState().thePlayer !== null, 'Player is null')
        const player = gameState().thePlayer
        // get bounds
        const { vx, vy, vw, vh } = map.getVirtualBounds()
        // const mapW = map.getW()
        // const mapH = map.getH()
        const updateList: Array<{ x: number, y: number }> = []
        const markVisible = (x: number, y: number) => {
            updateList.push({ x, y })
        }
        const isTransparent = (x: number, y: number) => {
            // also check we are within the map bounds
            if (x < vx || x >= vx + vw || y < vy || y >= vy + vh) {
                return false
            }

            // check see through
            if (!map.isInVirtualBounds(x, y)) {
                return false
            } else {
                const tile = map.get(x, y)
                const tileInfo = getTileInfo(tile)
                if (!tileInfo.canSeeThrough) {
                    return false
                }
            }

            // check if there is a thing
            const thing = map.getThing(x, y)
            if (thing && !thing.canSeeThrough && !thing.isFloating) {
                return false
            }

            return true
        }
        shadowcast(Math.floor(player.x), Math.floor(player.y), isTransparent, markVisible)

        // set fog of war to 1 in updateList
        for (const { x, y } of updateList) {
            map.setFowSafe(x, y, 1)
        }

        /* TODO: penumbra - this is more tricky than it seems
        const penumbraList = []
        // 0.51 the characters are still rendered, they are skipped if fog of war is <= 0.5
        const penumbra = 0.51
        // for every adjacent tile in updateList set fog of war to `penumbra` if not lit already
        for (const { x, y } of updateList) {
            // found border ligth/shadow
            // for ( const { dx, dy } of neighborOffsets) {
            for( let i = 0; i < neighborOffsets.length; ++i) {
                const { dx, dy } = neighborOffsets[i]
                const bx = Math.max(0, Math.min(x + dx, mapW - 1))
                const by = Math.max(0, Math.min(y + dy, mapH - 1))
                if (map.getFow(bx, by) === 0) {
                    penumbraList.push({ x: bx, y: by })
                    // break
                }
            }
        }

        // FIXME:
        // * These are the tiles that need to be enlarged, have their corners rounded and
        // with 2-3 pixel transparency on the border and irregular border.
        // * Bits should be computed here
        // set fog of war to penumbra in penumbraList
        for (const { x, y } of penumbraList) {
            map.setFogOfWar(x, y, penumbra)
            // TODO: Compute bits decoding the type of smoothed and enlarged tile to be used
            // ...
            // map.setFogOfWarPenumbra(x, y, bits)
        }
        //*/
    }

    // Detect visible chunks - based on player position
    detectVisibleChunks(): { cx: number, cy: number }[] {
        const map = this.getFloor()
        const player = gameState().thePlayer
        const tileRadius = map.virtualChunkW * MapChunk.CHUNK_SIZE / 2
        const x = player.x
        const y = player.y
        const minX = Math.floor(x - tileRadius)
        const minY = Math.floor(y - tileRadius)
        const maxX = Math.floor(x + tileRadius)
        const maxY = Math.floor(y + tileRadius)

        const visibleChunks: { cx: number, cy: number }[] = []
        const self = this
        // const camera = self.camera
        // assert(camera.viewLeft>=0, 'Camera x is negative')
        // assert(camera.viewTop>=0, 'Camera y is negative')
        // const minChunkX = Math.max(0, Math.floor(camera.viewLeft / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE))
        // const minChunkY = Math.max(0, Math.floor(camera.viewTop / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE))
        // // we are conservative here, we don't do: camera.viewLeft + camera.viewWidth - 1
        // const maxChunkX = Math.floor((camera.viewLeft + camera.viewWidth) / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE)
        // const maxChunkY = Math.floor((camera.viewTop + camera.viewHeight) / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE)
        const minChunkX = Math.max(0, Math.floor(minX / MapChunk.CHUNK_SIZE))
        const minChunkY = Math.max(0, Math.floor(minY / MapChunk.CHUNK_SIZE))
        // we are conservative here, we don't do: camera.viewLeft + camera.viewWidth - 1
        const maxChunkX = Math.floor(maxX / MapChunk.CHUNK_SIZE)
        const maxChunkY = Math.floor(maxY / MapChunk.CHUNK_SIZE)
        for (let cx = minChunkX; cx <= maxChunkX; ++cx) {
            for (let cy = minChunkY; cy <= maxChunkY; ++cy) {
                visibleChunks.push({ cx, cy })
            }
        }
        return visibleChunks
    }

    // MIC FIXME: world management logic
    // MIC FIXME: move this to a proper place outside of the rendering loop and into a world management logic
    // that happens before the rendering loop
    removeInvalidCharacters(): void {
        const floor = this.getFloor()
        const { vx, vy, vw, vh } = floor.getVirtualBounds()
        // remove all characters that are in an invalid position outside valid world bounds
        // iterate backwards to avoid skipping characters
        for (let i = floor.characters.length - 1; i >= 0; --i) {
            const character = floor.characters[i]
            if (character.x < vx || character.x >= vx + vw || character.y < vy || character.y >= vy + vh) {
                floor.characters.splice(i, 1)
            }
        }
    }

    // MIC FIXME: world management logic
    // ATM we actually only use the top left corner chunk to define the
    // entire virtual space.
    updateChunks(): void {
        const self = this
        const map = self.getFloor()
        assert(map !== null, 'Map is null (3))')
        const visibleChunks = self.detectVisibleChunks()
        const chunkGenerator = gameState().theChunkGenerator!
        const changedChunks = map.chunkSetOrigin(visibleChunks[0].cx, visibleChunks[0].cy, chunkGenerator, self.fogOfWarType)
        self.scheduleUpdateFogOfWar ||= changedChunks
        this.removeInvalidCharacters()
    }

    public getViewportPixel(viewBorder: number): { minX: number, minY: number, maxX: number, maxY: number } {
        const camera = this.camera
        const vb = viewBorder
        const minX = camera.viewLeft - vb
        const minY = camera.viewTop - vb
        const maxX = camera.viewRight() + vb
        const maxY = camera.viewBottom() + vb
        return { minX, minY, maxX, maxY }
    }

    public getViewportTiles(viewBorder: number): { minX: number, minY: number, maxX: number, maxY: number } {
        let { minX, minY, maxX, maxY } = this.getViewportPixel(viewBorder)
        const tilePixelSize = Config.TILE_PIXEL_SIZE
        minX = Math.floor(minX / tilePixelSize)
        minY = Math.floor(minY / tilePixelSize)
        maxX = Math.floor(maxX / tilePixelSize)
        maxY = Math.floor(maxY / tilePixelSize)
        return { minX, minY, maxX, maxY }
    }

    public getChunksInViewport(): { cx: number, cy: number }[] {
        const chunks: { cx: number, cy: number }[] = []
        const { minX, minY, maxX, maxY } = this.getViewportPixel(this.viewportBorder)
        const minChunkX = Math.max(0, Math.floor(minX / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE))
        const minChunkY = Math.max(0, Math.floor(minY / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE))
        const maxChunkX = Math.floor(maxX / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE)
        const maxChunkY = Math.floor(maxY / Config.TILE_PIXEL_SIZE / MapChunk.CHUNK_SIZE)

        for (let cy = minChunkY; cy <= maxChunkY; ++cy) {
            for (let cx = minChunkX; cx <= maxChunkX; ++cx) {
                chunks.push({ cx, cy })
            }
        }

        // console.log('Chunks in viewport:', chunks.length)
        // console.log('X:', maxChunkX - minChunkX + 1)
        // console.log('Y:', maxChunkY - minChunkY + 1)
        // console.log(minChunkX, minChunkY, maxChunkX, maxChunkY)

        return chunks
    }

    public renderChunk(ctx: RenderContext, charsByY: any, chunkX: number, chunkY: number): void {
        const self = this
        const minX = chunkX * MapChunk.CHUNK_SIZE
        const minY = chunkY * MapChunk.CHUNK_SIZE
        const maxX = minX + MapChunk.CHUNK_SIZE - 1
        const maxY = minY + MapChunk.CHUNK_SIZE - 1
        // console.log(`renderChunk(${chunkX}, ${chunkY}): ${minX}, ${minY}, ${maxX}, ${maxY}`)
        this.renderArea(ctx, charsByY, minX, minY, maxX, maxY)
    }

    /**
     * @method render
     * @description Renders the map on the canvas
     */
    public render(): void {
        const self = this

        const thePlayer = gameState().thePlayer

        // Update Camera and Fog of War
        if (thePlayer) {
            // continuous update
            self.camera.updateCameraFollowPlayer()

            if (thePlayer.x !== self.lastPlayerPosition.x || thePlayer.y !== self.lastPlayerPosition.y) {
                // self.camera.updateCameraFollowPlayer()

                // update fog of war only if player position changed (rounded)
                if (Math.floor(thePlayer.x) !== Math.floor(self.lastPlayerPosition.x) || Math.floor(thePlayer.y) !== Math.floor(self.lastPlayerPosition.y)) {
                    self.scheduleUpdateFogOfWar = true
                }

                self.lastPlayerPosition.x = thePlayer.x
                self.lastPlayerPosition.y = thePlayer.y
            }
        }

        // Adjust color 2 based on the color 1
        if (this.fogOfWarColor2.r === -1) {
            this.fogOfWarColor2 = this.fogOfWarColor.avgRGB() > 128 ?
                this.fogOfWarColor.mulRGB(0.98) : this.fogOfWarColor.mulRGB(1.55)
        }

        // ====================================================================
        // Canvas clear and init
        // ====================================================================
        const rc = this.mapCanvas.getRC()
        rc.initAndClearFrame(this.clearColor)
        // const ctx = this.mapCanvas.getContext()
        // const canvas = this.mapCanvas.getCanvas()
        // ctx.imageSmoothingEnabled = false
        // // reset transform to identity
        // ctx.setTransform(1, 0, 0, 1, 0, 0)

        // // Grid colour
        // ctx.strokeStyle = this.gridColor
        // ctx.lineWidth = 1
        // // Clear canvas
        // ctx.fillStyle = this.clearColor
        // ctx.fillRect(0, 0, canvas.width, canvas.height)

        // ====================================================================
        // Chunk logic
        // ====================================================================
        // ATM we actually only use the top left corner chunk to define the
        // entire virtual space.
        this.updateChunks()

        // ------------------------------------
        // Update Fog of War
        // ------------------------------------
        if (self.scheduleUpdateFogOfWar) {
            self.updateFogOfWar()
            self.scheduleUpdateFogOfWar = false
        }

        const map = self.getFloor()
        assert(map !== null, 'Map is null (3))')

        // MIC FIXME: move out of this loop or better, avoid doing this at all
        const charsByY: any = {}
        for (const character of map.characters) {
            const y = Math.floor(character.y)
            if (!charsByY[y]) {
                charsByY[y] = []
            }
            charsByY[y].push(character)
        }

        // Camera translation
        rc.translate(-self.camera.viewLeft, -self.camera.viewTop)

        if (1) {
            const chunks = this.getChunksInViewport()
            for (const { cx, cy } of chunks) {
                if (map.isChunkValid(cx, cy)) {
                    this.renderChunk(rc, charsByY, cx, cy)
                }
            }
        } else {
            const { vx, vy, vw, vh } = map.getVirtualBounds()
            const minX = vx
            const minY = vy
            const maxX = vx + vw - 1
            const maxY = vy + vh - 1

            // console.log(minX, minY, maxX, maxY)
            this.renderArea(rc, charsByY, minX, minY, maxX, maxY)
        }

        this.renderFoW(rc)

        // MIC FIXME: char paths and hit animations should be rendered inside the chunk they belong to
        // and hit animation rendered after the player
        self.renderHitAnimation(rc)
        for (const character of map.characters) {
            self.renderCharPath(character, rc)
        }

        self.renderGrid(rc)

        // Undo camera translation
        rc.setTransform(1, 0, 0, 1, 0, 0)

        self.renderGUI(rc)
    }

    protected renderArea(ctx: RenderContext, charsByY: any, minX: number, minY: number, maxX: number, maxY: number): void {
        const self = this

        // console.log(`renderArea(${minX}, ${minY}, ${maxX}, ${maxY})`)

        // crop minX, minY, maxX, maxY to the viewport
        const camera = self.camera
        // Viewport border: allows large trees and bottom wall to not disappear
        const vb = this.viewportBorder
        minX = Math.max(minX, Math.floor((camera.viewLeft - vb) / Config.TILE_PIXEL_SIZE))
        minY = Math.max(minY, Math.floor((camera.viewTop - vb) / Config.TILE_PIXEL_SIZE))
        maxX = Math.min(maxX, Math.floor((camera.viewRight() + vb) / Config.TILE_PIXEL_SIZE))
        maxY = Math.min(maxY, Math.floor((camera.viewBottom() + vb) / Config.TILE_PIXEL_SIZE))

        const thePlayer = gameState().thePlayer

        // const tileColorMap: { [key: number]: string } = Config.tileColorMap
        const map = self.getFloor()
        assert(map !== null, 'Map is null (3))')
        const thingShadowTexture = TextureUtils.getTexture('Item-197')

        // const visibleTiles: Array<{ x: number, y: number }> = []

        const tw = Config.TILE_PIXEL_SIZE
        const th = Config.TILE_PIXEL_SIZE
        const xhcm = Config.TILE_PIXEL_SIZE // extra height cull margin
        for (let y = minY; y <= maxY; ++y) {
            const oy = y * Config.TILE_PIXEL_SIZE // - camera.viewTop
            // Y tile culling
            // if (oy + th < 0 || oy >= camera.viewHeight + xhcm) {
            //     continue
            // }
            // const ty = y * Config.TILE_PIXEL_SIZE // - camera.viewTop

            // Pass 1 - X row
            for (let x = minX; x <= maxX; ++x) {
                // const ox = x * Config.TILE_PIXEL_SIZE - camera.viewLeft
                // X tile culling
                // if (ox + tw < 0 || ox >= camera.viewWidth) {
                //     continue
                // }
                const ox = x * Config.TILE_PIXEL_SIZE // - camera.viewLeft

                // visibleTiles.push({ x, y })

                // render terrain tile
                const tileId = map.get(x, y)
                const thisTileInfo = getTileInfo(tileId)
                const isWall = thisTileInfo.level === TileLevel.WALL
                const yOffset = isWall ? th / 2 : 0
                const texture: Texture = thisTileInfo.texture!
                assert(texture !== null, `Texture is null`)
                assert(texture.fullH > 0, `Texture fullH is 0`)
                assert(texture.fullW > 0, `Texture fullW is 0`)
                assert(texture.tw > 0, `Texture tw is 0`)
                assert(texture.th > 0, `Texture th is 0`)
                if (texture) {
                    const texW = texture.tw
                    const texH = texture.th
                    // cycle through the texture based on tile x and y
                    const texX = x * Config.TILE_PIXEL_SIZE % texW
                    const texY = y * Config.TILE_PIXEL_SIZE % texH
                    ctx.drawImage(
                        // texture.handle!,
                        texture,

                        // source
                        texture.tx + texX, texture.ty + texY,
                        Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE,

                        // destination
                        ox, oy - yOffset,
                        tw, th
                    )
                    if (isWall) {
                        // draw front side of the wall
                        // ctx.fillStyle = thisTileInfo.averageColor.toCSS()
                        ctx.setFillColor(thisTileInfo.averageColor)
                        ctx.fillRect(
                            ox,
                            oy + yOffset,
                            tw,
                            th - yOffset
                        )
                    }
                }

                // wall shadow
                if (1) {
                    if (isWall) {
                        const isInside = map.isInVirtualBounds(x, y + 1)
                        // Cast shadow both in front of floor and substractum
                        if (isInside && getTileInfo(map.get(x, y + 1)).level <= TileLevel.FLOOR) {
                            ctx.drawImage(
                                TextureUtils.getTexture('Shadow-2'),
                                // source
                                0, 0, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE,
                                // destination
                                ox, oy, tw, th
                            )
                        }
                    }

                    if (thisTileInfo.canWalkThrough) {
                        let drawn = [0, 0, 0, 0, 0, 0, 0, 0]
                        const offsets = [
                            { x: 0, y: -1, i: 0, a: -1, b: -1 }, // top
                            { x: 1, y: 0, i: 1, a: -1, b: -1 }, // right
                            { x: 0, y: 1, i: 2, a: -1, b: -1 }, // bottom
                            { x: -1, y: 0, i: 3, a: -1, b: -1 }, // left
                            // corners
                            { x: -1, y: -1, i: 4, a: 0, b: 3 }, // top left
                            { x: 1, y: -1, i: 5, a: 0, b: 1 }, // top right
                            { x: -1, y: 1, i: 6, a: 3, b: 2 }, // bottom left
                            { x: 1, y: 1, i: 7, a: 1, b: 2 }, // bottom right
                        ]
                        for (const p of offsets) {
                            // index
                            const newX = x + p.x
                            const newY = y + p.y
                            const isInside = map.isInVirtualBounds(newX, newY)
                            if (!isInside) {
                                continue
                            }
                            // do not draw corners if not all adjacent tiles are walls
                            if (p.i >= 4 && (drawn[p.a] || drawn[p.b])) {
                                continue
                            }
                            const tileId = map.get(newX, newY)
                            if (getTileInfo(tileId).level === TileLevel.WALL) {
                                drawn[p.i] = 1
                                ctx.drawImage(
                                    TextureUtils.getTexture('Shadow-' + p.i),
                                    // source
                                    0, 0, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE,
                                    // destination
                                    ox, oy, tw, th
                                )
                            }
                        }
                    }
                } // wall shadow
            } // x

            // MIC FIXME: cull things using camera thing bbox culling
            // Pass 2 - X row
            for (let x = minX; x <= maxX; ++x) {
                const ox = x * Config.TILE_PIXEL_SIZE // - camera.viewLeft
                // X tile culling
                // if (ox + tw < 0 || ox >= camera.viewWidth) {
                //     continue
                // }
                // const tx = x * Config.TILE_PIXEL_SIZE - camera.viewLeft

                // --- render things ---

                const thing = map.getThing(x, y)
                if (thing) {
                    let texture = thing.isFloating ? thing.textureIcon! : thing.texture!
                    assert(texture !== null, `Texture is null for thing "${thing.name}/${thing.type}"`)

                    // Float animation offset
                    let yOffset = 0
                    // const texW = texture.fullW
                    // const texH = texture.fullH
                    const texW = texture.tw
                    const texH = texture.th
                    let adjX = 0
                    let adjY = 0
                    let srcCrop = 0

                    if (thing.isFloating) {
                        const floatRange = 4
                        const floatSpeed = 2 // + (thing.floatOffset / Math.PI)
                        yOffset = -1 - (Math.sin((GameTime.now() * Math.PI + thing.floatOffset) * floatSpeed) + 1) * 0.5 * floatRange
                        srcCrop = 2
                    } else {
                        if (thing.name.startsWith('Tree-')) {
                            adjX = Math.floor(- thing.texturePivot.x + Config.TILE_PIXEL_SIZE / 2)
                            adjY = Math.floor(- thing.texturePivot.y + Config.TILE_PIXEL_SIZE / 2)
                        }
                    }

                    // shadow if floating
                    if (thing.isFloating) {
                        ctx.drawImage(thingShadowTexture,

                            // source
                            thingShadowTexture.tx, thingShadowTexture.ty,
                            thingShadowTexture.tw, thingShadowTexture.th,

                            // destination
                            ox, oy,
                            Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE
                        )
                    }

                    // check if we need transparency due to proximity to the player
                    if (adjX !== 0 || adjY !== 0) {
                        // it's a large object, check if it's close to the player
                        const player = thePlayer!
                        // player world position
                        const px = player.x * Config.TILE_PIXEL_SIZE
                        const py = player.y * Config.TILE_PIXEL_SIZE
                        // thing world position
                        const tx = (x + 0.5) * Config.TILE_PIXEL_SIZE
                        const ty = (y + 0.5) * Config.TILE_PIXEL_SIZE
                        // distance between player and thing
                        const dx = Math.abs(tx - px)
                        const dy = Math.abs(ty - py)
                        if (dx <= -adjX * 1 && dy <= -adjY * 1 && py < ty) {
                            // thing is close to the player, make it transparent
                            // ctx.globalAlpha = 0.5
                            ctx.setGlobalAlpha(0.5)
                        }
                    }

                    // thing
                    ctx.drawImage(texture,
                        // source
                        texture.tx + srcCrop,
                        texture.ty + srcCrop,

                        // texture.width, texture.height,
                        texW - srcCrop * 2,
                        texH - srcCrop * 2,

                        // destination
                        ox + adjX + srcCrop,
                        oy + yOffset + adjY + srcCrop,
                        texW - srcCrop * 2,
                        texH - srcCrop * 2
                    )

                    if (ctx.getGlobalAlpha() !== 1) {
                        // reset transparency
                        ctx.setGlobalAlpha(1)
                    }

                    // draw pile count
                    if (thing.pileCount > 1) {
                        // draw text at x y position
                        ctx.setFillColor(new RGBA(255, 255, 255, 255))
                        ctx.setTextBaseline('bottom')
                        ctx.setFont('8px monospace')
                        ctx.fillText(thing.pileCount + '', ox, oy + yOffset + Config.TILE_PIXEL_SIZE - 1)
                        // ctx.fillStyle = 'white'
                        // ctx.textBaseline = 'bottom'
                        // ctx.font = '8px monospace'
                        // ctx.fillText(thing.pileCount + '', ox, oy + yOffset + Config.TILE_PIXEL_SIZE - 1)
                    }
                }
            }

            // --- render characters on this Y row ---

            // MIC FIXME: duplicated rendering of characters - only render chars in the visible min/max x/y range
            // MIC FIXME: handle tall objects or things or chars that are in non visible chunks but are visible due to their width/height
            const chars: Character[] = charsByY[y] || []
            // render characters
            for (const character of chars) {
                const texture: Texture = character.texture!
                assert(texture !== null, `Texture is null for character "${character.name}/${character.type}"`)
                if (!texture) {
                    continue
                }

                // MIC FIXME: do this better - for now only render chars once, the ones allocated to this chunk
                const cx = Math.floor(character.x)
                const cy = Math.floor(character.y)
                if (cx < minX || cx > maxX || cy < minY || cy > maxY) {
                    continue
                }

                // if fog is <= 0.5 then skip rendering
                // allows to render characters in the penumbra at 0.51 fog of war value (see updateFogOfWar)
                const fog = map.getFow(Math.floor(character.x), Math.floor(character.y))
                if (fog <= 0.5) {
                    continue
                }

                const ox = (character.x - 0.5) * Config.TILE_PIXEL_SIZE // - camera.viewLeft
                const oy = (character.y - 0.5) * Config.TILE_PIXEL_SIZE // - camera.viewTop

                const sw = texture.tw * 1
                const sh = texture.th * 1
                const floorOffset = 4
                let dx = Math.floor(ox - (sw - Config.TILE_PIXEL_SIZE) / 2)
                let dy = Math.floor(oy - (sh - Config.TILE_PIXEL_SIZE) - floorOffset)

                // culling based on dx, dy, sw, sh vs canvas dimensions
                // MIC FIXME: don't shift by camera and user camera.viewRight/Bottom
                // if (dx + sw < 0 || dx > camera.viewWidth) {
                //     continue
                // }

                // // MIC FIXME: don't shift by camera and user camera.viewRight/Bottom
                // if (dy + sh < 0 || dy > camera.viewHeight) {
                //     continue
                // }

                ctx.drawImage(texture,
                    // source
                    texture.tx, texture.ty, texture.tw, texture.th,
                    // destination
                    dx, dy, sw, sh
                )
            }

        } // y
    }

    renderFoW(ctx: RenderContext): void {
        const self = this

        // ,,, test Fractal noise function ... + weighted normalization
        // ,,, test bisection noise function ... + weighted normalization

        // FIXME: Temporarily disabled for continuous testing
        /*
        if (self.fogOfWarType === FogOfWarType.NONE) {
            return
        }
        */

        const map = self.getFloor()

        // Another way to compute all visible tiles at once but we clamp to the virtual bounds
        // clamp minX, minY, maxX, maxY to the virtual bounds
        let { minX, minY, maxX, maxY } = this.getViewportTiles(this.viewportBorder)
        const { vx, vy, vw, vh } = map.getVirtualBounds()
        // also add +/-1 border so we don't sample FoW outside of the map
        minX = Math.max(minX, vx + 1)
        minY = Math.max(minY, vy + 1)
        maxX = Math.min(maxX, vx + vw - 1 - 1)
        maxY = Math.min(maxY, vy + vh - 1 - 1)

        const tw = Config.TILE_PIXEL_SIZE
        const th = Config.TILE_PIXEL_SIZE

        // --- render Fog of War over everything else ---

        // Fog fo war
        // for (const { x, y } of visibleTiles) {
        for (let y = minY; y <= maxY; ++y) {
            // Pass 1 - X row
            for (let x = minX; x <= maxX; ++x) {
                const oy = y * Config.TILE_PIXEL_SIZE // - camera.viewTop
                const ox = x * Config.TILE_PIXEL_SIZE // - camera.viewLeft
                const fow = map.getFow(x, y)
                const alpha = 1 - fow
                if (alpha === 0) {
                    // fog of war smooth edges
                    let drawn = [0, 0, 0, 0, 0, 0, 0, 0]
                    const offsets = [
                        { x: 0, y: -1, i: 0, a: -1, b: -1 }, // top
                        { x: 1, y: 0, i: 1, a: -1, b: -1 }, // right
                        { x: 0, y: 1, i: 2, a: -1, b: -1 }, // bottom
                        { x: -1, y: 0, i: 3, a: -1, b: -1 }, // left
                        // corners
                        { x: -1, y: -1, i: 4, a: 0, b: 3 }, // top left
                        { x: 1, y: -1, i: 5, a: 0, b: 1 }, // top right
                        { x: -1, y: 1, i: 6, a: 3, b: 2 }, // bottom left
                        { x: 1, y: 1, i: 7, a: 1, b: 2 }, // bottom right
                    ]
                    for (const p of offsets) {
                        // index
                        const newX = x + p.x
                        const newY = y + p.y
                        const alpha2 = 1 - map.getFow(newX, newY)

                        // do not draw corners if not all adjacent tiles are walls
                        if (p.i >= 4 && (drawn[p.a] || drawn[p.b])) {
                            continue
                        }
                        if (alpha2 !== 0) {
                            drawn[p.i] = 1
                            ctx.drawImage(
                                TextureUtils.getTexture('Shadow-FoW-' + p.i),
                                // source
                                0, 0, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE,
                                // destination
                                ox, oy - th / 2, tw, th
                            )
                        }
                    }
                }
                // fog of war
                const fowWave = self.fogOfWarClouds.get(x, y)
                const color = fowWave === 0 && alpha === 1 ? this.fogOfWarColor : this.fogOfWarColor2
                // ctx.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`
                ctx.setFillColor(new RGBA(color.r, color.g, color.b, alpha * 255))
                ctx.fillRect(ox, oy - th / 2, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE)
            }
        }
    }

    renderHitAnimation(ctx: RenderContext): void {
        const self = this
        const hitTexture = TextureUtils.getTexture('Item-198')

        // TODO: sorting and culling
        // Render hit animation
        const thePlayer = gameState().thePlayer
        if (thePlayer && thePlayer.hitAnimation.timeStart !== 0) {
            const t = (GameTime.now() - thePlayer.hitAnimation.timeStart) / thePlayer.hitAnimation.duration
            if (t >= 0 && t <= 1) {
                const hx = thePlayer.x * Config.TILE_PIXEL_SIZE + thePlayer.hitAnimation.dx - hitTexture.tw / 2
                const hy = thePlayer.y * Config.TILE_PIXEL_SIZE + thePlayer.hitAnimation.dy - hitTexture.th / 2 - thePlayer.texture!.tw / 4
                // rotate around center of texture
                const angle = t * Math.PI * 1
                const scale = 2 - t * 1.5
                ctx.saveState()
                ctx.translate(hx + hitTexture.tw / 2, hy + hitTexture.th / 2)
                ctx.scale(scale, scale)
                ctx.rotate(angle)
                ctx.translate(-(hx + hitTexture.tw / 2), -(hy + hitTexture.th / 2))
                ctx.drawImage(hitTexture,
                    // source
                    hitTexture.tx, hitTexture.ty,
                    hitTexture.tw, hitTexture.th,
                    // destination
                    hx, hy, hitTexture.tw, hitTexture.th,
                )
                // reset transform
                ctx.restoreState()
            }
        }
    }

    renderGrid(ctx: RenderContext): void {
        const self = this

        ctx.saveState()

        const camera = self.camera
        const left = camera.viewLeft
        const top = camera.viewTop
        const right = camera.viewRight()
        const bottom = camera.viewBottom()

        ctx.setStrokeColor(new RGBA(0, 0, 0, 255))
        ctx.setLineWidth(1)
        // ctx.strokeStyle = '#000000'
        // ctx.lineWidth = 1

        if (this.drawVirtualCamera) {
            ctx.drawLine(left, top, right, bottom)
            ctx.drawLine(right, top, left, bottom)
            // middle cross
            // ctx.beginPath()
            // ctx.moveTo(left, top)
            // ctx.lineTo(right, bottom)
            // ctx.moveTo(right, top)
            // ctx.lineTo(left, bottom)
            // ctx.stroke()

            // draw rectangle
            ctx.setStrokeColor(new RGBA(255, 255, 255, 255))
            // ctx.strokeStyle = '#FFFFFF'
            const border = Config.TILE_PIXEL_SIZE * 2
            ctx.strokeRect(
                left + border + 0.5,
                top + border + 0.5,
                right - left - border * 2,
                bottom - top - border * 2
            )
        }

        // typically used for static renderings & galleries
        if (this.renderGridEnabled) {

            ctx.setStrokeColor(new RGBA(0, 0, 0, 255 * 0.25))
            // ctx.strokeStyle = 'rgba(0, 0, 0, 0.25)'

            const k = Config.TILE_PIXEL_SIZE
            // vertical rows
            // ctx.beginPath()
            for (let x = Math.floor(left / k) * k; x <= Math.floor(right / k) * k; x += Config.TILE_PIXEL_SIZE) {
                ctx.drawLine(x + 0.5, top, x + 0.5, bottom)
                // ctx.moveTo(x + 0.5, top)
                // ctx.lineTo(x + 0.5, bottom)
            }
            // ctx.stroke()

            // horizontal rows
            // ctx.beginPath()
            for (let y = Math.floor(top / k) * k; y <= Math.floor(bottom / k) * k; y += Config.TILE_PIXEL_SIZE) {
                ctx.drawLine(left, y + 0.5, right, y + 0.5)
                // ctx.moveTo(left, y + 0.5)
                // ctx.lineTo(right, y + 0.5)
            }
            // ctx.stroke()
        }

        ctx.restoreState()
    }

    renderGUI(ctx: RenderContext) {
        const self = this
        if (!self.isGallerySimpleRender) {
            let isGUIHover = false
            if (gameState().theGui) {
                const theGui = gameState().theGui!
                theGui.render(ctx, gameState().userInput!.mousePositionCanvas)
                isGUIHover = theGui.isHover(gameState().userInput!.mousePositionCanvas)
            }

            const isRenderDrag = this.renderDragThing(ctx)

            if (!isGUIHover && !isRenderDrag) {
                // render mouse position
                self.renderMousePosition(ctx)
            }
        }
    }

    renderDragThing(ctx: RenderContext): boolean {
        // render mouse position as a red rectangle
        const thing = gameState().theGui!.thingSlotGroup!.getDragThing()
        if (thing) {
            const texture = thing.textureIcon
            assert(texture !== null, `Texture is null for thing "${thing.name}/${thing.type}"`)
            if (texture) {
                const sw = texture.tw
                const sh = texture.th
                assert(sw === Config.TILE_PIXEL_SIZE && sh === Config.TILE_PIXEL_SIZE, 'Drag thing texture size is not tile size')
                const cx = gameState().userInput!.mousePositionCanvas.x - sw / 2
                const cy = gameState().userInput!.mousePositionCanvas.y - sh / 2

                ctx.drawImage(texture,

                    // source
                    texture.tx, texture.ty,
                    sw, sw,

                    // destination
                    cx, cy, sw, sh
                )

                // draw pile count
                if (thing.pileCount > 1) {
                    // draw text at x y position
                    ctx.setFillColor(new RGBA(255, 0, 0, 255))
                    ctx.setFont('8px monospace')
                    ctx.setTextBaseline('top')
                    // ctx.fillStyle = 'red'
                    // ctx.font = '8px monospace'
                    // ctx.textBaseline = 'top'
                    ctx.fillText(thing.pileCount + '', cx, cy)
                }
            }
            return true
        } else {
            return false
        }
    }

    renderMousePosition(ctx: RenderContext): void {
        const self = this

        // render mouse position as a red rectangle
        const cx = gameState().userInput!.mousePositionTileTopLeft.x
        const cy = gameState().userInput!.mousePositionTileTopLeft.y
        ctx.setStrokeColor(new RGBA(255, 0, 0, 255))
        ctx.setLineWidth(1)
        // ctx.strokeStyle = 'red'
        // ctx.lineWidth = 1
        ctx.strokeRect(cx, cy, Config.TILE_PIXEL_SIZE, Config.TILE_PIXEL_SIZE)
        // const tx = this.mousePositionTile.x
        // const ty = this.mousePositionTile.y
        // console.log('Mouse Render:', tx, ty)
    }

    renderCharPath(char: Character, ctx: RenderContext): void {
        const self = this

        const path = char.physPath
        // render playerPath as a line
        if (path.length > 0) {
            // push translation matrix by 0.5
            // ctx.saveState()
            // ctx.translate(0.5, 0.5)

            ctx.setFillColor(new RGBA(0, 0, 0, 255 * 0.50))
            ctx.setStrokeColor(new RGBA(0, 0, 0, 255 * 0.50))
            ctx.setLineWidth(1)
            // ctx.strokeStyle = 'rgb(0, 0, 0, 0.50)'
            // ctx.fillStyle = 'rgb(0, 0, 0, 0.50)'
            // ctx.lineWidth = 1

            // dash line
            // ctx.setLineDash([2, 2])

            for (let i = 1; i < path.length; ++i) {
                ctx.drawLine(
                    path[i - 1].x * Config.TILE_PIXEL_SIZE + 0.5,
                    path[i - 1].y * Config.TILE_PIXEL_SIZE + 0.5,
                    path[i].x * Config.TILE_PIXEL_SIZE + 0.5,
                    path[i].y * Config.TILE_PIXEL_SIZE + 0.5,
                )
            }
            /*
            ctx.beginPath()
            ctx.moveTo(
                path[0].x * Config.TILE_PIXEL_SIZE,
                path[0].y * Config.TILE_PIXEL_SIZE,
            )
            for (let i = 1; i < path.length; ++i) {
                ctx.lineTo(
                    path[i].x * Config.TILE_PIXEL_SIZE,
                    path[i].y * Config.TILE_PIXEL_SIZE,
                )
            }
            ctx.stroke()
            */

            // reset transformation & setLineDash
            // ctx.setLineDash([])
            // ctx.restoreState()

            const side = 3
            for (let i = 0; i < path.length; ++i) {
                ctx.fillRect(
                    path[i].x * Config.TILE_PIXEL_SIZE - Math.floor(side / 2),
                    path[i].y * Config.TILE_PIXEL_SIZE - Math.floor(side / 2),
                    side, side)
            }
        }
    }
}

///////////////////////////////////////////////////////////////////////////////
// RendererAbstract
///////////////////////////////////////////////////////////////////////////////
export class RendererCanvas extends RendererAbstract {
    constructor(camera: Camera, mapCanvas: MapCanvas) {
        super(camera, mapCanvas)
    }
}

///////////////////////////////////////////////////////////////////////////////
// RendererGL
///////////////////////////////////////////////////////////////////////////////
export class RendererGL extends RendererAbstract {
    constructor(camera: Camera, mapCanvas: MapCanvas) {
        super(camera, mapCanvas)
    }
}
